import './App.scss';

import Preloader from './components/Preloader/Preloader';
import Banner from './components/Banner/Banner';
import Slogan from './components/Slogan/Slogan';
import Action from './components/Action/Action';
import Mask from './components/Mask/Mask';
import About from './components/About/About';
import Beverages from './components/Beverages/Beverages';
import Food from './components/Food/Food';
import Beer from './components/Beer/Beer';
import Services from './components/Services/Services';
import Menu from './components/Menu/Menu';
import Footer from './components/Footer/Footer';

import React, {useState, useEffect} from 'react';

const SkipToMainContent = () => {
  return (
    <a tabIndex="0" href='#skiptomain' className='skip-to-main-content-link'>Skip to main content</a>
  )
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 7000);
  }, []);

  return (
    <React.Fragment>
      <Preloader /> 
      { !loading &&
        <div className="stellco">
          <SkipToMainContent />
          <Action />
          <Banner>
            <Slogan />
          </Banner>
          <Mask />
          <About />
          <Beverages />
          <Food />
          <Beer />
          <Services />
          <Menu />
          <Footer />
        </div>
      }
    </React.Fragment>
  );
}

export default App;
